
import Footer from '../footer'
import '../home.css'
import './medDevice.css'
import '../../App.css'
import SmartSlider from "react-smart-slider";
import healyLogo from "../../asset/Healy-Horizontal-Logo-with-Dot-RGB@2x-1.png"
import healyDevice from "../../asset/med-device/560x400_healy-wellness.png"
import magHealyDevice from "../../asset/med-device/maghealy-header-element-1.png"
import healyWatch from "../../asset/med-device/healyWatch.png"
import healyCoil from "../../asset/med-device/healy_coil_header_element.png"
import healyApp from "../../asset/med-device/digital-app-device.png"
import healySearch from "../../asset/med-device/560x645_healadvisor-expert-database_EN.png"
import healyPet from "../../asset/med-device/560x400_healy-imf-programs-1.png"
import healySoul from "../../asset/med-device/560x-soul-cycle-phone-healy.png"
import buyNowBg from "../../asset/med-device/buynow-bg.jpg"
import { TH, US, AU, KR, IN, CA, EU } from 'country-flag-icons/react/3x2'



const FisrtSection = () => {
    const headerStyle = {
        fontSize: '120%',
        fontFamily: 'Barlow-semi-condense-bold',
        color: 'white',
        textShadow: '0px 1px 3px rgba(0,0,0,0.7)'
    }
    const pStyle = {
        fontSize: '55%',
        fontFamily: 'Barlow-condense-medium',
        color: 'white',
        textShadow: '0px 1px 3px rgba(0,0,0,0.7)'
    }
    const pStyleSmall = {
        fontSize: '50%',
        fontFamily: 'Barlow-condense-medium',
        color: 'white',
        textShadow: '0px 1px 3px rgba(0,0,0,0.7)'
    }

    const pStyleRight = {
        fontSize: '50%',
        fontFamily: 'Barlow-condense-medium',
        color: 'white',
        textShadow: '0px 1px 3px rgba(0,0,0,0.7)'
    }

    const pStyleBlack = {
        fontSize: '50%',
        fontFamily: 'Barlow-condense-medium',
        color: 'white',
        textShadow: '0px 1px 3px rgba(0,0,0,0.7)'

    }
    const textContainerLeft = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80%',
        lineHeight: '1.5',
        fontSize: '100%',
    }
    const textContainerRight = {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '90%',
        lineHeight: '1.5',
        fontSize: '100%',
        textAlign: 'right',
    }
    const slidesArray = [
        {
            url: "/medDevice/healy-bg.png",
            // (Optional) Set if you want to add any content on your slide
            childrenElem: <div style={{
                position: 'absolute',
                right: 65,
                top: 80,
                fontSize: 38,
                padding: 55,
            }}>
                <img src={healyLogo} alt="healy" />
                <div style={{
                    position: 'absolute',
                    paddingTop: 25,
                    fontSize: 28,
                    fontFamily: 'Barlow',
                    fontStyle: 'semi-condense-bold',
                    color: 'white',
                    textShadow: '0px 4px 3px rgba(0,0,0,0.4)'

                }}> Medical Device for your wellness</div>

            </div >
        },
        { // healy
            url: "/medDevice/gradient-bg1.jpeg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 38,
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'space-around'
                }}>
                    <div>
                        <img style={{ display: 'flex', maxWidth: 500, paddingLeft: '10%', marginBottom: 0, top: 10 }} src={healyDevice} alt="healy" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 38,
                    height: '100%',
                    top: 60,
                    paddingLeft: 50
                }}>
                    <div style={textContainerLeft}>
                        <h style={headerStyle}>The Healy</h>
                        <p style={pStyle}>The Wearable for Holistic Health,</p>
                        <p style={pStyle}>Wellbeing and Vitality</p>
                    </div>
                </div>
            </div>
        },
        { // magHealy
            url: "/medDevice/gradient-bg3.jpeg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 38,
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'space-around'
                }}>
                    <div>
                        <img style={{ display: 'flex', width: '90%', maxWidth: 500, paddingLeft: '10%', marginBottom: 0, top: 10 }} src={magHealyDevice} alt="magHealy" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 38,
                    height: '100%',
                    top: 60,
                    paddingLeft: 50
                }}>
                    <div style={textContainerLeft}>
                        <h style={headerStyle}>The MagHealy </h>
                        <p style={pStyle}> For Harmony in Your Environment</p>
                        <p style={pStyle}>Energize your body, your environment and your drinking water.</p>
                    </div>
                </div>
            </div>
        },
        { // healyWatch
            url: "/medDevice/gradient-bg4.jpeg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'space-around'
                }}>
                    <div>
                        <img style={{ display: 'flex', width: '80%', maxWidth: 500, paddingLeft: '10%', marginBottom: 0, top: 10 }} src={healyWatch} alt="magHealy" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 38,
                    height: '100%',
                    top: 60,
                    paddingLeft: 50
                }}>
                    <div style={textContainerLeft}>
                        <h style={headerStyle}>Healy Watch </h>
                        <p style={pStyle}> Determine Your Body Mind Soul Balance</p>
                        <p style={pStyle}> measures your bioenergetic state through an analysis of BRA and HRV,<br></br> quickly and easily.</p>
                    </div>
                </div>
            </div>
        },
        { // healyCoil
            url: "/medDevice/gradient-bg5.jpeg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'space-around'
                }}>
                    <div>
                        <img style={{ display: 'flex', width: '80%', maxWidth: 600, paddingLeft: '10%', marginBottom: 0, top: 10 }} src={healyCoil} alt="healyPet" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 38,
                    height: '100%',
                    top: 60,
                    paddingLeft: 50
                }}>
                    <div style={textContainerLeft}>
                        <h style={headerStyle}>Healy Coil </h>
                        <p style={pStyle}> Cable-free Harmonization of the Bioenergetic Field</p>
                        <p style={pStyle}> use the new Healy Coil to transmit Healy frequencies without cables!</p>
                    </div>
                </div>
            </div>
        },
        { // healyApp
            url: "/medDevice/gradient-bg8.jpg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-start'
                }}>
                    <div>
                        <img style={{ display: 'flex', width: '90%', maxWidth: 600, paddingLeft: '10%', marginBottom: 0, top: 10 }} src={healyApp} alt="healyApp" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 30,
                    height: '100%',
                    top: 60,
                }}>
                    <div style={textContainerRight}>
                        <h style={headerStyle}>The Digital Nutrition App </h>
                        <p style={pStyleRight}>Discover the Digital Nutrition Application (DNA*) </p>
                        <p style={pStyleRight}>Formula: Balanced nutrition from the right foods and harmonization of the Bioenergetic Field</p>
                        <p style={pStyleRight}>with Healy Individualized Microcurrent Frequency. programs can better support your wellbeing than over-reliance on supplements.</p>
                    </div>
                </div>
            </div>
        },
        { // healySearch
            url: "/medDevice/gradient-bg6.jpeg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'space-around'
                }}>
                    <div>
                        <img style={{ display: 'flex', width: '80%', maxWidth: 500, paddingLeft: '20%', marginBottom: 0, top: 10 }} src={healySearch} alt="healySearch" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 35,
                    height: '100%',
                    left: 50,
                    top: 60,
                }}>
                    <div style={textContainerLeft}>
                        <h style={{
                            fontSize: '110%',
                            fontFamily: 'Barlow-semi-condense-bold',
                            color: 'white',
                            textShadow: '0px 4px 3px rgba(0,0,0,0.4)'
                        }}>HealAdvisor Search </h>
                        <p style={pStyle}>Personal Guide to Fitness and Wellbeing</p>
                        <p style={pStyle}>Find the Healy Program for Your Needs.</p>
                    </div>
                </div>
            </div>
        },
        { // healyPet
            url: "/medDevice/close-up-couple-with-dog-indoors.jpg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '95%',
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'space-around'
                }}>
                    <div>
                        <img style={{ display: 'flex', width: '90%', maxHeight: '100%', paddingLeft: '20%', marginBottom: 0, top: 10 }} src={healyPet} alt="healySearch" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    fontSize: 35,
                    height: '100%',
                    left: 60,
                    top: 60,
                }}>
                    <div style={textContainerLeft}>
                        <h style={{
                            fontSize: '90%',
                            fontFamily: 'Barlow-semi-condense-bold',
                            color: 'white',
                            textShadow: '0px 1px 2px rgba(0,0,0,0.8)'

                        }}>Healy For Animals</h>
                        <p style={pStyleBlack}>Start Supporting Your Pet’s</p>
                        <p style={pStyleBlack}> Wellbeing With the HealAdvisor Animal Module</p>
                    </div>
                </div>
            </div>
        },
        { // healySoul
            url: "/medDevice/stacked-zen-marble-stones-sand-background-mindfulness-concept.jpg",
            childrenElem: <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'

                }}>
                    <div>
                        <img style={{ display: 'flex', width: '95%', maxHeight: '100%', paddingRight: '10%', marginBottom: 0, top: 10 }} src={healySoul} alt="healySoul" />
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    width: '95%',
                    fontSize: 38,
                    height: '100%',
                    top: 60,
                    paddingLeft: 50
                }}>
                    <div style={textContainerLeft}>
                        <h style={headerStyle}>Soul Cycle</h>
                        <p style={pStyleSmall}> Every human being possesses their own spectrum of unique frequencies.</p>
                        <p style={pStyleSmall}> The goal of Soul Cycle’s group of programs is to bring these negative frequencies back into harmony.</p>
                    </div>
                </div>
            </div>
        },
    ];
    return (<div className='slider-container'>
        <SmartSlider
            slides={slidesArray}
            showIndicators={false}
            buttonShape="square" // round or square
            height={600}
            autoSlide={false}
        />
    </div>)
}

const SecondSection = () => {
    return (<div className='second-wraper'>
        <div className='second-container-buy'>
            <img src={buyNowBg} alt="bg-part" />
        </div >
        <div className='second-container-content'>
            <h1>Buy now</h1>
            <p>Select your country or region</p>
            <div className='box-wrapper'>
                <div className='buynow-box'>
                    <h>Canada</h>
                    <CA onClick={() => { window.location.href = 'https://canada.healy.shop/?partnername=9014-5816-5575'; }} title="Canada" className='icon-flag'></CA>
                </div>
                <div className='buynow-box'>
                    <h>Thailand</h>
                    <TH onClick={() => { window.location.href = 'https://thailand.healy.shop/?partnername=9014-5816-5575'; }} title="Thailand" className='icon-flag'></TH>
                </div>
                <div className='buynow-box'>
                    <h>Korea</h>
                    <KR onClick={() => { window.location.href = 'https://korea.healy.shop/?partnername=9014-5816-5575'; }} title="Korea" className='icon-flag'></KR>
                </div>
                <div className='buynow-box'>
                    <h>India</h>
                    <IN onClick={() => { window.location.href = 'https://india.healy.shop/?partnername=9014-5816-5575'; }} title="India" className='icon-flag'></IN>
                </div>
                <div className='buynow-box'>
                    <h>United State</h>
                    <US onClick={() => { window.location.href = 'https://us.healy.shop/?partnername=9014-5816-5575'; }} title="United State" className='icon-flag'></US>
                </div>
                <div className='buynow-box'>
                    <h>Australia</h>
                    <AU onClick={() => { window.location.href = 'https://au.healy.shop/?partnername=9014-5816-5575'; }} title="Australia" className='icon-flag'></AU>
                </div>
                <div className='buynow-box'>
                    <h>Europe</h>
                    <EU onClick={() => { window.location.href = 'https://eu.healy.shop/?partnername=9014-5816-5575'; }} title="Europe" className='icon-flag'></EU>
                </div>
                <div className='buynow-box'>
                    <h onClick={() => { window.location.href = 'https://asia.healy.shop/?partnername=9014-5816-5575'; }}>Asia</h>
                </div>
            </div>

        </div>
    </div >)

}

const MedDevice = props => {
    return (
        <div className='home-page'>
            <div className="home-container">
                <FisrtSection />
                <SecondSection />
                <Footer />
            </div>
        </div >
    )

}

export default MedDevice