
import Footer from '../footer'
import './privacy.css'
import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { definitions } from './constants'
import md from './verve_ppc.md'

const GetContentWithSubP = () => Object.entries(definitions).map(([topic, content]) => {
    return <p><h2>{topic}</h2>
        <div className='sub-paragraph' ><p>{content}</p></div  >
    </p >
})
const FisrtSection = () => {
    return (<div className='ag-container'>
        <div className='ag-sub-container'>
            <h1>Privacy Policy</h1>
        </div>

        <div className='ag-alone-container'>
            <p>
                We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the BacTrace BioTec AG. The use of the Internet pages of the BacTrace BioTec AG is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.

                The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to the BacTrace BioTec AG. By means of this data protection declaration, our enterprise would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.

                As the controller, the BacTrace BioTec AG has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone.

            </p>
            <br></br>
            {GetContentWithSubP()}
        </div>
    </div >)

}

const Content = () => {
    let [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(md)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <div className='p-6'>
            < div className='flex flex-col items-center justify-center p-6' >
                <h1 className='font-bold text-2xl items-center py-4'>Datenschutzerklärung</h1>

            </div >
            <ReactMarkdown parserOptions={{ commonmark: true }} children={content.md} remarkPlugins={[remarkGfm]} />
        </div >
    )
}
const Privacy = () => {
    return (
        <div className="about-us">
            <div className="aboutus-container">
                <Content />
                <Footer />
            </div>
        </div>

    )

}

export default Privacy