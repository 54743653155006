import footerBG from '../asset/footer_bg.png'
import TheVerveLogo from '../asset/The_Verve_Logo.png'
import './footer.css'
import IG from '../asset/icons8-instagram.svg'
import Email from '../asset/icons8-mail-30.png'
import { BrowserRouter as Router, useRoutes, Link } from "react-router-dom";

const LinkIn = "https://static.wixstatic.com/media/7528824071724d12a3e6c31eee0b40d4.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7528824071724d12a3e6c31eee0b40d4.png"
const Facebook = "https://static.wixstatic.com/media/7528824071724d12a3e6c31eee0b40d4.png/v1/fill/w_50,h_50,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7528824071724d12a3e6c31eee0b40d4.png"
const Tel = "https://static.wixstatic.com/media/292978_94a2111d1afa4707b6b2d00ec616d8cf~mv2.png/v1/fill/w_42,h_42,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Icon.png"

const Footer = () => {
    return (
        <div>
            <div className="px-[100px] flex lg:flex-row lg:gap-10 gap-0 flex-col justify-between text-white items-center my-auto bg-[#00469c] min-h-[200px] p-6" >
                <div className='flex flex-row justify-center lg:justify-start'>
                    <img className='lg:flex hidden w-[100px] h-[100px]' src={TheVerveLogo} alt="footerLogo"></img>
                    <div className='flex flex-col'>
                        <img className='lg:hidden flex w-[70px] self-center' src={TheVerveLogo} alt="footerLogo"></img>
                        <h1 className='lg:text-[50px] text-[40px] font-semi-condense lg:text-start text-center'>The Verve</h1>
                        <p className='font-condense-medium lg:text-start text-center'> Burgstr 12  80331 . München</p>
                    </div>
                </div>
                <div className='flex flex-col gap-2 font-condense-medium lg:gap-0  lg:text-[15px] text-[18px]'>
                    <h className='lg:hidden text-[20px] pt-6 py-2 text-center'>Contact Us</h>
                    <div className="flex flex-row gap-2 lg:justify-start justify-center items-center">
                        <img className='lg:w-[14px] lg:h-[14px] w-[18px] h-[18px]' src={Tel} alt="tel-icon" />
                        <div> +49 (160)99031158 </div>
                    </div>

                    <a className="flex flex-row gap-2 lg:justify-start justify-center items-center" href="mailto:polly@verve27.com?subject='Inquire!'&body=Dear Verve27">
                        <img className='lg:w-[14px] lg:h-[14px] w-[18px] h-[18px]' src={Email} alt="tel-icon" />
                        <div> polly@verve27.com </div>
                    </a>


                    <div className="flex flex-row gap-2 lg:justify-start justify-center items-center">
                        <img className='lg:w-[14px] lg:h-[14px] w-[18px] h-[18px]' src={Facebook} alt="tel-icon" />
                        <div> The Verve </div>
                    </div>
                    <div className="flex flex-row gap-2 lg:justify-start justify-center items-center">
                        <img className='lg:w-[14px] lg:h-[14px] w-[18px] h-[18px]' src={IG} alt="tel-icon" />
                        <div> verve_27 </div>
                    </div>

                </div>
            </div >
            <div className='mx-auto p-4 bg-black text-white flex flex-row justify-center items-center'>
                <p className='text-center lg:text-[12px] max-md:text-[6px]'>
                    © 2023 The Verve. All rights reserved.<br></br>
                    This website contains information intended for a general audience and may contain product details or information not otherwise accessible or valid in your country.<br></br>
                    Please note that we accept no responsibility for access to such information which may not conform to legal process, regulation, registration or use in your country of origin.
                    <br></br>
                    <div className='flex flex-row gap-2 justify-center decoration underline'>
                        <Link className='text-center lg:text-[12px] max-md:text-[6px]' to="/privacy-policy">
                            Privacy Policy
                        </Link>
                        <Link className='text-center lg:text-[12px] max-md:text-[6px]' to="/imprint">
                            Impressum
                        </Link>
                    </div>

                </p>

            </div>


        </div >


    )
}
export default Footer
