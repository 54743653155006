
import './aesthetic.css'
import MediaCard from './mediaCard'
import facelift from '../../asset/facelift.jpeg'
import needling from '../../asset/needling.jpeg'
import fat from '../../asset/fat-transfer.jpg'
import prp from '../../asset/preparation-blood-injections-cosmetologist-puts-tube-blood-centrifuge.jpg'
import Footer from '../footer'
import { Typography } from '@mui/material'

const FisrtSection = () => {
    return (<div className='flex flex-row flex-wrap justify-center gap-6 py-6' >
        <div className='md:w-[400px] lg:w-[600px]'>
            <MediaCard
                imageUrl={facelift}
                topic="Ultherapy"
                description="strengthen and tighten tissues without surgery."
                context={
                    <div>
                        <Typography>
                            Ultherapy is the only FDA-approved device to strengthen and tighten tissues without surgery.
                            <br></br>
                            We recommend the use of Ultherapy on the face, cheeks, lower eyelids, chin line, neck and eyebrows. Ultherapy also has a good effect on wrinkles in the d&eacute;collet&eacute;.
                        </Typography>
                        <br></br>

                        <Typography>
                            <h className='font-bold'>Advantages</h>
                            <p>
                                1.Depending on the skin type, there are hardly any side effects and little downtime By tightening the subcutaneous tissue, the fine wrinkles in the treated areas can also be reduced.
                            </p>
                            <p>
                                2.The method shows an immediate effect by shrinking the existing collagen and increases the formation of new collagen in the coming months.
                            </p>
                            <p>
                                3.Ultherapy is suitable for all skin types and is indicated for mild to moderate skin.
                            </p>
                        </Typography>
                        <br></br>

                        <Typography>
                            <h className='font-bold'>Disadvantages</h>
                            <p>
                                1.In the case of severe skin laxity, the consistency of the tissue can also be strengthened and thus the appearance of the skin improved, but the firming effect will not be sufficient. Although it is often advertised as a &quot;non-surgical facelift,&quot; the &quot;lifting&quot; effects and durability measured by surgical facelift in severe skin laxity are not comparable.
                            </p>
                            <p>
                                2.Treatment can be painful. We give painkillers before therapy to improve the comfort of treatment.
                            </p>
                            <p>
                                The main effect of treatment is noticeable only after 4 to 6 months.
                            </p>
                        </Typography>
                        <br></br>

                        <Typography>
                            "Ultherapy is a non-invasive treatment with focused ultrasound. Without damaging the skin surface, the tissue under the skin is heated by ultrasound technology, which stimulates the natural collagen growth of the tissue. One treatment is enough to stimulate collagen, the full effect of which develops over 2 to 4 months. "
                        </Typography>
                        <br></br>
                        <Typography>
                            <h className='font-bold'>Cost</h><br />
                            The cost of the treatment is determined by the number of individual pulses and depends on the size and skin texture.
                            The average cost is € 2600, - with a range of €1600, - to €6000, -
                        </Typography>
                    </div>
                }>
            </MediaCard >
        </div>
        <div className='md:w-[400px] lg:w-[600px]'>
            <MediaCard imageUrl={needling} topic="Needling"
                description='The aim of the treatment is to achieve skin rejuvenation, thus treating skin rejuvenation, superficial wrinkles or slight scars.'
                context={<div>
                    <Typography>This procedure stimulates the collagen fibers in the upper layers of the skin and treats fine wrinkles and skin texture.
                        A stamp pad with over 20 finest micro-needles penetrates the skin mechanically to a depth of 1.5-2mm. These micro-injuries activate the body's self-healing powers, collagen is formed and firms the skin.
                    </Typography>
                    <br></br>
                    <Typography>
                        In addition to general skin rejuvenation, fine wrinkles and superficial scars can also be treated at this low needle penetration depth. The aim of the treatment is to achieve skin rejuvenation, thus treating skin rejuvenation, superficial wrinkles or slight scars.
                    </Typography>
                    <br></br>
                    <Typography>
                        <h className='font-bold'>Advantages</h>
                        <p>
                            1.The superficial punctures lead only to minor swelling and redness, comparable to a sunburn.
                        </p>
                        <p>
                            2.Low downtime of 1 to 2 days Suitable for all ages and skin types Depending on the condition of the skin, one treatment may be sufficient.
                        </p>
                        <p>
                            3.Unlike ablative measures (abrasion, laser and chemical peeling), the skin does not become thinner and remains stable against exogenous damage.
                        </p>
                    </Typography>
                    <br></br>
                    <Typography>
                        <h className='font-bold'>Disadvantages</h>
                        <p>
                            1.Painful treatment, therefore local anesthesia with an anesthetic cream is required to improve the comfort of the treatment.
                        </p>
                        <p> 2.The effect becomes visible only after 2 to 4 months.</p>
                        <p>
                            Depending on the skin condition, further treatments are recommended at intervals of 4-6 weeks
                        </p>
                        <p>3.Contraindication in Couperose and rosacea</p>
                    </Typography>
                    <br></br>
                    <Typography>
                        <h className='font-bold'>Costs</h>
                        <p>  The costs depend on skin condition and number of treated areas
                            Average costs are € 600, - , with a range of €450,- to €2000,-.</p>
                    </Typography>

                </div>}></MediaCard>
        </div>
        <div className='md:w-[400px] lg:w-[600px]'>
            <MediaCard imageUrl={prp} topic="Biostimulation with PRP"
                description='restoring moisture to the skin and creates freshness on the face. It is also widely used for dark circles under the eyes and to strengthen hair growth'
                context={
                    <div>
                        <Typography>
                            The abbreviation "PRP" stands for platelet-rich plasma.
                            It is an innovative treatment method in which the patient's blood is taken and then centrifuged. The body's own cells and growth factors contained in the plasma are removed and can be brought directly back into the tissue in a variety of procedures and stimulate the synthesis of collagen and hyaluron.
                        </Typography>
                        <br></br>
                        <Typography>
                            We recommend injecting PRP primarily for skin rejuvenation. It restores moisture to the skin and creates freshness on the face. It is also widely used for dark circles under the eyes and to strengthen hair growth
                        </Typography>
                        <br></br>
                        <Typography>
                            <h className='font-bold'>Advantages</h>
                            <p>  1.minimally invasive procedure
                                perfect symbiosis with a needling treatment
                                Own tissue, thus hardly any risk of allergy formation
                                Improves cell metabolism and skin elasticity
                                Treats several concerns: improving skin texture, skin firmness and reducing wrinkle depth
                            </p>
                            <p>  2. Short downtime Onset of action after only a few days
                                Treatment of skin diseases, e.g. couperose and rosacea
                            </p>
                        </Typography>
                        <br></br>
                        <Typography>
                            <h className='font-bold'>Disadvantages</h>
                            <p>
                                1.Hematomas, redness and swelling possible
                            </p>
                            <p>
                                2.No painless treatment, that's why we use local anesthesia with anesthetic cream
                            </p>
                            <p>
                                3.Contraindication in chronic, infectious and oncological diseases
                            </p>
                        </Typography>
                        <br></br>
                        <Typography>
                            <h className='font-bold'>Costs</h>
                            <p>
                                Depending on the number of areas and, if necessary, combination with further procedures (often needling)
                                Average price €1000, - with a € 800,- and maximum €1800,-
                            </p>
                        </Typography>
                    </div>
                }></MediaCard>
        </div>
        <div className='md:w-[400px] lg:w-[600px]'>
            <MediaCard imageUrl={fat} topic="Autologous fat under fueling"
                description='remove the fat, fatty tissue is suctioned sterile in an inconspicuous place. After a treatment process, the removed fat is injected into the desired areas'
                context={
                    <div>
                        <Typography>
                            Autologous fat can be used to compensate for acquired or age-related volume losses, build up sunken facial areas, change contours or correct deep wrinkles. It also promises good success with dark circles under the eyes.
                        </Typography>
                        <br></br>
                        <Typography>
                            To remove the fat, fatty tissue is suctioned sterile in an inconspicuous place. After a treatment process, the removed fat is injected into the desired areas.
                        </Typography>
                        <br></br>
                        <Typography>
                            Depending on the extent, we perform this treatment under local anesthesia with tumescence or under general anesthesia.
                        </Typography>
                        <br></br>
                        <Typography>
                            <h className='font-bold'>Advantages</h>
                            <p> 1.The body's own material, thus good compatibility
                            </p>
                            <p>  2.No allergic reactions
                            </p>
                            <p>
                                3.Long-term shelf life
                            </p>
                        </Typography>
                        <br></br>
                        <Typography>
                            <h className='font-bold'>Disadvantages</h>
                            <p>
                                1.Operational procedure
                            </p>
                            <p>
                                2.Uncertain growth of fat cells
                            </p>
                            <p>
                                3.Possible bruising and swelling for several days
                            </p>
                        </Typography>
                        <br></br>
                        <Typography>
                            <h className='font-bold'>Cost</h>
                            <p>
                                The costs depend on the extent and number of areas treated.
                                Average cost, without anesthesia, € 5000, - with a range between € 4000, - and € 8000,-
                            </p>
                        </Typography>
                    </div>
                }></MediaCard>
        </div>
    </div >)
}

// const SecondSection = () => {
//     return (<div className='about-us-white-sec'>
//         <div className='about-us-second-sec-container'>
//             <div className='about-us-box-content'>
//                 <h1>OUR VISION</h1>
//                 <p>Vertical Integration and State of the Art Internal Systems/Solutions<br />
//                     Strong Customer Relations Built on Trust<br />
//                     Solid Financial Position<br />
//                     Employee and Community Respect<br />
//                     Technology<br />
//                     Operational Excellence<br /></p>
//             </div>
//             <div className='about-us-box-content2'>
//                 <h1>OUR MISSION</h1>
//                 <p> &nbsp;&nbsp;Precision Medical Products and Therapy, Inc. is a medical device manufacturer committed to providing the highest level of excellence to our stakeholders through innovation,
//                     vertical integration and comprehensive value-added services to offer a complete single source solution.
//                     <br /> &nbsp;&nbsp;To inspire hope, and contribute to health and well-being by providing the best care to every patient through integrated clinical practice, education and research.</p>
//             </div>

//         </div>
//     </div >)

// }

// const ThirdSection = () => {
//     return (<div className="ceo-section">
//         <div>
//             <img src={ceoPic} alt="ceoPic" />
//             <h1>CEO</h1>
//             <p>Tientam Junjuajarn</p>
//         </div>
//         <div>
//             <h1>COO</h1>
//             <p>XXX XXXXX</p>
//         </div>
//         <div>
//             <h1>Manager</h1>
//             <p>XXX XXXXX</p>
//         </div>
//     </div>)
// }
const Aesthetic = props => {
    return (
        <div>
            <FisrtSection ></FisrtSection>
            <Footer />
        </div>
    )

}

export default Aesthetic