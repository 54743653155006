import sec1BG from '../asset/introduce_bg1024.png'
import whiteBG from '../asset/home-white-bg.png'
import joinUs from '../asset/joinUs-container.png'
import DrMedLogo from '../asset/dr-med-logo.svg'
import WidenLogo from '../asset/Widen-logo.png'
import BacTraceLogo from '../asset/BacTrace-BioTecAG-Logo.png'
import Footer from './footer'
import './home.css'

const FisrtSection = () => {
    return (
        <div>
            <div className="home-section" >
                <img src={sec1BG} />
                <div className='text-container'>
                    <div>
                        <h1> Medical and Heath Tech Innovation </h1>
                    </div>
                    <div>
                        <p>Vertical Integration and State of the Art Internal Systems/Solutions
                            Strong Customer Relations Built on Trust
                            Solid Financial Position
                            Employee and Community Respect
                            Technology
                            Operational Excellence
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

const SecondSection = () => {
    return (<div>
        <div className="p-6 relative flex flex-col justify-center items-center gap-[100px]" >
            <div className='flex flex-col items-center gap-4 justify-center top-0 x-inset-1/2 lg:pt-[50px] md:pt-[20px]'>
                <h1 className='text-2xl font-bold font-semi-condense text-[#00469c]'>OUR PARTNERS</h1>
                <div className='flex flex-row flex-wrap gap-10 items-center  justify-center'>
                    <img className='lg:w-[300px] max-md:w-[150px]' src={DrMedLogo} width="300" alt="dr. nicolas martin company" />
                    <img className='lg:w-[300px] max-md:w-[150px]' src={WidenLogo} width="300" alt=" Widenmayor company" />
                    <img className='lg:w-[300px] max-md:w-[150px]' src={BacTraceLogo} width="300" alt=" BacTrace company" />
                </div>
            </div>
            <div className='p-6'>
                <img className='min-w-[300px]' src={joinUs} />
            </div>
        </div>

    </div>)
}
const HomePage = props => {
    return (
        <div className='home-page'>
            <div className="home-container">
                <FisrtSection />
                <SecondSection />
                <Footer />
            </div>
        </div>


    )

}

export default HomePage