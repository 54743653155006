
import Footer from '../footer'
import './imprint.css'
import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import md from './verve_ppc.md'

const Content = () => {
    let [content, setContent] = useState({ md: "" });
    useEffect(() => {
        fetch(md)
            .then((res) => res.text())
            .then((md) => {
                setContent({ md })
            })
    }, [])

    return (
        <div className='p-6'>
            < div className='flex flex-col items-start justify-center p-6 mt-6' >
                <h1 className='font-bold text-3xl py-6 text-[#00469c]'>Impressum</h1>
                <p>The Verve Life Science GmbH<br />Burgstr. 12  80331  München</p>
                <p>Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links.<br /> Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
                <h1 className='font-semibold text-xl py-2 text-[#00469c]'>Angaben gemäß § 5 TMG:</h1>
                <p>The Verve Life Science GmbH<br />Burgstr. 12  80331 München</p>
                <p>Telefon: +49 (160)99031158<br />email: polly@verve27.com<br />
                    Geschäftsführer: Tientam Junjuajarn<br />
                    Finanzamt München Steuer Nr: 143/186/12723<br />
                    Amtsgericht  München  HRB 187546</p>
            </div >
        </div >
    )
}
const Imprint = () => {
    return (
        <div className="bg-white">
            <div className="felx felx-col">
                <Content />
                <Footer />
            </div>
        </div>

    )

}

export default Imprint