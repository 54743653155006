
import './aboutUs.css'
import Footer from '../footer'
import TheVerve from '../../asset/The Verve Logo.jpg'
import ceoPic from '../../asset/dr-hans.png'
import goPic from '../../asset/tientam.png'
import teamBG from '../../asset/19862.jpg'
import headAnti from '../../asset/profile-dr-martin.png'

const FisrtSection = () => {
    return (<div className='about-us-white-sec'>
        <div className='about-us-first-sec-container'>
            <img src={TheVerve} alt="the-verve-aboutus-page" />

        </div>
    </div>)
}

const SecondSection = () => {
    return (<div className='about-us-white-sec'>
        <div className='about-us-second-sec-container'>
            <div className='about-us-box-content'>
                <h1>OUR VISION</h1>
                <p>Vertical Integration and State of the Art Internal Systems/Solutions<br />
                    Strong Customer Relations Built on Trust<br />
                    Solid Financial Position<br />
                    Employee and Community Respect<br />
                    Technology<br />
                    Operational Excellence<br /></p>
            </div>
            <div className='about-us-box-content2'>
                <h1>OUR MISSION</h1>
                <p> &nbsp;&nbsp;Precision Medical Products and Therapy, Inc. is a medical device manufacturer committed to providing the highest level of excellence to our stakeholders through innovation,
                    vertical integration and comprehensive value-added services to offer a complete single source solution.
                    <br /> &nbsp;&nbsp;To inspire hope, and contribute to health and well-being by providing the best care to every patient through integrated clinical practice, education and research.</p>
            </div>

        </div>
    </div >)

}

const ThirdSection = () => {
    return (
        <div className="ceo-wrapper">
            <div className="ceo-section">
                <div className='ceo-box'>
                    <h1>Dr. Hans-Hermann Wörl</h1>
                    <div className='ceo-box-img'> <img src={ceoPic} alt="ceoPic" /></div>
                    <h2>Head of Aesthetic</h2>
                    <p2>Specialist in plastic and Aesthetic surgery of</p2>
                    <p2>Widenmayer</p2>
                </div>
                <div className="ceo-box">
                    <h1>Tientam Junjuajarn</h1>
                    <div className='ceo-box-img'><img src={goPic} alt="GM" /></div>
                    <h2>Chief Executive Officer</h2>
                </div>
                <div className="ceo-box">
                    <h1>Dr.Med Nicolas Martin</h1>
                    <div className='ceo-box-img'> <img src={headAnti} alt="CO Anti aging" /></div>
                    <h2>Head of Anti-aging</h2>
                    <p2>Specialist in Plastic and Aesthetic Surgery of</p2>
                    <p2>Dr. Med. Nicolas Martin</p2>
                </div>
            </div>
            <img src={teamBG} className='ceo-bg' alt="the-verve-page" />

        </div>
    )
}
const AboutUsPage = props => {
    return (
        <div className="about-us">
            <div className="aboutus-container">
                <FisrtSection />
                <SecondSection />
                <ThirdSection />
                <Footer />
            </div>
        </div>

    )

}

export default AboutUsPage